import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = ({ pageContext }) => {
  return (
    <Layout pageContext={pageContext}>
      <SEO pageContext={pageContext} title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>The path you accessed is undefined...</p>
    </Layout>
  );
};

export default NotFoundPage;
